import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms-and-conditions"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"We help you answer all your questions regarding architecture, design and construction issues."} />
			<meta property={"og:title"} content={"my architect help | expertise within reaach"} />
			<meta property={"og:description"} content={"We help you answer all your questions regarding architecture, design and construction issues."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1548248823-ce16a73b6d49?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/apple-touch-icon.png?v=2024-07-10T21:30:27.429Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-192x192.png?v=2024-07-10T21:30:46.961Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6682bb661d712a002335b89b/images/android-chrome-512x512.png?v=2024-07-10T21:30:58.923Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="16px 40px 16px 40px" quarkly-title="Header" position="absolute" sm-padding="16px 20px 16px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
				width="100%"
				max-width="none"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Components.Logo />
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" padding="0px 0px 0px 0px" background="none" />
					<Override
						slot="Button Text"
						md-text-transform="uppercase"
						md-color="--darkL2"
						md-letter-spacing="1px"
						md-font="--base"
					/>
					<Override slot="Button Icon" md-color="--darkL2" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link
							text-decoration-line="initial"
							color="--darkL2"
							font="--base"
							href="#"
							display="none"
						>
							WhatsApp
						</Link>
						<Link
							md-margin="20px 0px 0px 0px"
							href="#"
							text-decoration-line="initial"
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							display="none"
						>
							LinkedIn
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="#"
							text-decoration-line="initial"
							display="none"
						>
							Instagram
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section
			padding="100px 40px 100px 40px"
			quarkly-title="HeroBlock"
			background="linear-gradient(0deg,rgba(249, 248, 243, 0.2) 0%,rgba(249, 248, 243, 0.6) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1720180320321-2a3d719d14f8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
			sm-padding="100px 20px 100px 20px"
			height="400px"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="300px"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0px 0px 0"
				color="--darkL2"
				font="--headline1"
				width="60%"
				lg-font="--headline1Lg"
				sm-font="--headline2Lg"
				sm-width="100%"
				height="300px"
			>
				expertise
				<br />
				within
				<br />
				reach
			</Text>
			<Text
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--darkL2"
				sm-text-align="left"
				font="--headline3"
				width="40%"
				text-align="right"
				lg-font="--headline3Lg"
				sm-width="100%"
				height="300px"
			>
				<Span
					font-weight="400"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					TERMS AND CONDITIONS
				</Span>
			</Text>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-flex-direction="column"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				sm-min-width="280px"
				lg-align-items="center"
				lg-display="flex"
				lg-justify-content="center"
				lg-font="--lead"
			/>
			<Box
				width="100%"
				md-width="100%"
				lg-width="100%"
				lg-align-items="center"
				lg-display="flex"
				lg-flex-direction="row"
				lg-justify-content="center"
			>
				<Components.TermsAndConditions />
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" sm-padding="40px 20px 40px 20px" quarkly-title="Footer" background="--color-green">
			<Override
				slot="SectionContent"
				md-align-items="center"
				flex-direction="row"
				md-flex-direction="column"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-align-items="flex-start"
				sm-min-width="280px"
			/>
			<Box width="100%" margin="0px 0px 100px 0px">
				<Components.Logo />
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				align-items="start"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="repeat(1, 1fr)"
			>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					md-justify-content="center"
					md-align-items="center"
					md-padding="16px 16px 16px 16px"
					md-width="100%"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					sm-align-items="flex-start"
					sm-padding="16px 0px 16px 0px"
					margin="0px 24px 0px 0px"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						color="--darkL2"
						sm-text-align="center"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						About us
					</Text>
					<Link
						href="/about-us"
						color="--darkL2"
						font="--base"
						margin="0px 0px 0px 0px"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Our Story
					</Link>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						display="none"
					>
						Career
					</Link>
					<Link
						href="/privacy-policy"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Privacy Policy
					</Link>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					md-justify-content="center"
					md-align-items="center"
					md-padding="16px 16px 16px 16px"
					md-width="100%"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					sm-align-items="flex-start"
					sm-padding="16px 0px 16px 0px"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						color="--darkL2"
						sm-text-align="center"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						CONTACTS
					</Text>
					<Link
						href="mailto:info@myarchitecthelp.com"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="left"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						info@myarchitecthelp.com
					</Link>
					<Link
						href="tel:2087432000"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="left"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					/>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="left"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					/>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					md-justify-content="center"
					md-align-items="center"
					md-padding="16px 16px 16px 16px"
					md-width="100%"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="none"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					sm-align-items="flex-start"
					sm-padding="16px 0px 16px 0px"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						color="--darkL2"
						sm-text-align="center"
						text-transform="uppercase"
						letter-spacing="1px"
					>
						FOLLOW US
					</Text>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						WhatsApp
					</Link>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						LinkedIn
					</Link>
					<Link
						href="#"
						color="--darkL2"
						margin="0px 0px 0px 0px"
						font="--base"
						sm-text-align="center"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
					>
						Instagram
					</Link>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});